import React, { createContext, useContext, useState } from "react";

const FacilityDetailsContext = createContext();

export const useFacilityDetails = () => {
  const context = useContext(FacilityDetailsContext);
  if (!context) {
    throw new Error(
      "useFacilityDetails must be used within a FacilityDetailsProvider"
    );
  }
  return context;
};

// eslint-disable-next-line react/prop-types
export const FacilityDetailsProvider = ({ children }) => {
  const [facilityDetails, setFacilityDetails] = useState([]);

  return (
    <FacilityDetailsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        facilityDetails,
        setFacilityDetails,
      }}
    >
      {children}
    </FacilityDetailsContext.Provider>
  );
};
